import appLogo from './Logo.png';
import google from './google.png';
import microsoft from './microsoft.png';
import addIcon from './add.svg';
import sendIcon from './send.png';
import attachIcon from './attach.png';
import nextIcon from './next.png';
import closeIcon from './close.png';
import profileIcon from './profile.png';
import homeIcon from './home.png';
import logoutIcon from './logout.png';
import messageIcon from './message.png';
import tiger from './tiger.jpeg';

function Assets() {
    return null;
  }
export default Assets ={
    appLogo,
    google,
    microsoft,
    addIcon,
    sendIcon,
    attachIcon,
    nextIcon,
    closeIcon,
    profileIcon,
    homeIcon,
    logoutIcon,
    messageIcon,
    tiger
};